.login_container {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    height: 10vh;
    background: rgb(138,168,191);
background: linear-gradient(90deg, rgba(138,168,191,1) 0%, rgba(44,98,123,0.6979166666666667) 36%, rgba(0,212,255,1) 100%);
}
.login {
    padding: 20px 20px 20px 0
}

.select {
    align-self: center;
    padding: 5px;
    font-size: .7vw;

}
option {
    font-size: .7vw;
}

h4 {
    margin:0
}

.react-select-container{
    width: 400px;
    align-self: center;
}
.login_middle {
    position: absolute;
    left: 50%;
    top: 50%;
    justify-content: center;
    transform: translate(-50%, -50%);
    width: 40vw;
    height: 40vh;
    background-color: aqua;
}
.buttonIN {
    position: absolute;
    left: 50%;
    top: 50%;
    justify-content: center;
    transform: translate(-50%, -50%);
}