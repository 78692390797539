.frame {
    position: absolute;
    top: 0;
    right: 0vw;
    width: 80vw;
    height: 89vh;
    margin: auto;
    align-items: center;
}
iframe {
    display:block;
    width:100%;
    height: 100%;
    overflow-x: scroll;
    border: none
}

.Collapsible__trigger {
    font-weight: 700;
    cursor:pointer
    
}

a {
    text-decoration: none;
    color: #0000EE;
  }
  
  a:hover {
    color: rgb(138,168,191);
    color: linear-gradient(90deg, rgba(138,168,191,1) 0%, rgba(44,98,123,0.6979166666666667) 36%, rgba(0,212,255,1) 100%);
    text-decoration: none;
    cursor: pointer;
  }

  .dashboard_container_group {
    padding-left: 10px;
  }





