.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wrapper {
  position: absolute;
  width: 100vw;
  height: 90vh;
  display: flex;
}
.aside {
  width: 20vw;
  font-size: 14px;
  overflow-wrap: break-word;
  overflow-y: scroll;
  background-color: #f4f3f3;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 10px;

  
}

.aside::-webkit-scrollbar {
display: none}/* 

.aside::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.aside::-webkit-scrollbar-thumb {
  background: #8AA8BF
}


.aside::-webkit-scrollbar-thumb:hover {
  background: #90C4D7
} */

.aside {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.main {
  width: 80vw;

/*   background-color: red; */
}
